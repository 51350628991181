<template>
    <div class="section">
        <page-title :title="title" class="ml-2"></page-title>
        <b-container fluid>
            <b-card>
                <spinner v-if="refreshData"></spinner>
                <custom-table
                    v-if="!refreshData"
                    :tableConfig="tableConfig"
                    :fields="fields"
                    :items="items"
                    @radioButtonTableFilterChange="updateItems"
                    @tableFiltered="onTableFiltered"
                    @sortingColumnChanged="saveSortingParams"
                    @pageChanged="savePage"
                    :page="page"
                    :key="tableConfig.radioButtonSelectedOption"
                ></custom-table>
            </b-card>
        </b-container>
    </div>
</template>

<script>
import PageTitle from '@/layouts/components/PageTitle.vue';
import { SstRepository } from '@/repositories';
import CustomTable from '@/components/shared/table/CustomTable';
import Spinner from '@/components/shared/element/Spinner';
import { INTERVENTION } from '@/constants/sst/intervention';
import { TABLE } from '@/constants';
import store from '@/store';
import {
    TABLE_FILTER_CHANGE,
    TABLE_FILTERED_ITEMS_LENGTH_CHANGE,
    TABLE_PAGE_CHANGE,
    TABLE_RADIO_TAB_CHANGE,
    TABLE_SCROLL_POSITION_CHANGE,
    TABLE_SORTING_CHANGE
} from '../../../store/actions/interventionTableState';

export default {
    name: 'SstInterventionListe',
    components: {
        PageTitle,
        CustomTable,
        Spinner
    },
    data() {
        return {
            title: this.$t('sst.pages.intervention.title'),
            items: [],
            tableConfig: {
                sortBy: 'alert_date',
                striped: true,
                bordered: true,
                headVariant: 'dark',
                sortDesc: true,
                currentPage: 1,
                displayCount: true,
                displayTotalRows: false,
                radioButtonsOptions: [
                    {
                        text: this.$t('sst.pages.intervention.table.radioButtonFilter.active'),
                        value: INTERVENTION.PURCHASE_STATUS.PROCESSING
                    },
                    {
                        text: this.$t('sst.pages.intervention.table.radioButtonFilter.closed'),
                        value: INTERVENTION.PURCHASE_STATUS.TODO
                    },
                    {
                        text: this.$t('sst.pages.intervention.table.radioButtonFilter.payment'),
                        value: INTERVENTION.PURCHASE_STATUS.TO_BE_PAID
                    }
                ],
                radioButtonSelectedOption: INTERVENTION.PURCHASE_STATUS.PROCESSING
            },
            allFields: [
                {
                    key: 'intervention_request_id',
                    label: this.$t('sst.pages.intervention.table.columns.call'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'site.name',
                    label: this.$t('sst.pages.intervention.table.columns.site'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'reason',
                    label: this.$t('sst.pages.intervention.table.columns.reason'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'html',
                    label: this.$t('sst.pages.intervention.table.columns.state'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'alert_date',
                    label: this.$t('sst.pages.intervention.table.columns.date'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'actions',
                    label: this.$t('sst.pages.intervention.table.columns.actions'),
                    sortable: false,
                    class: 'text-center'
                }
            ],
            refreshData: true,
            data: [],
            page: TABLE.PAGE.SST.INTERVENTION.ID,
            fields: [],
        };
    },
    created() {
        if (store.getters['interventionTableState/page']) {
            this.tableConfig.currentPage = store.getters['interventionTableState/page'];
        }
        if (store.getters['interventionTableState/radioTab']) {
            this.tableConfig.radioButtonSelectedOption = store.getters['interventionTableState/radioTab'];
        } else {
            this.$store.dispatch(`interventionTableState/${TABLE_RADIO_TAB_CHANGE}`, this.tableConfig.radioButtonSelectedOption);
        }
        if (store.getters['interventionTableState/sortBy']) {
            this.tableConfig.sortBy = store.getters['interventionTableState/sortBy'];
            this.tableConfig.sortDesc = store.getters['interventionTableState/sortDesc'];
        }
        if (store.getters['interventionTableState/searchFilter']) {
            this.tableConfig.filter = store.getters['interventionTableState/searchFilter'];
        }
        window.addEventListener('scroll', this.saveScrollPosition);
        this.getData().then(() => {
            if (store.getters['interventionTableState/scrollPosition']) {
                window.scrollTo(0, store.getters['interventionTableState/scrollPosition']);
            }
        });
    },
    destroyed() {
        window.removeEventListener('scroll', this.saveScrollPosition);
    },
    methods: {
        getData() {
            this.refreshData = true;
            return SstRepository.getInterventions()
                .then(response => {
                    this.refreshData = false;
                    this.fields = this.allFields;

                    // set all extracted data
                    this.data = response.data.data;

                    // set data for tab response
                    this.items = response.data.data;

                    // color by status
                    this.items.map(element => {
                        if (element.status.key === INTERVENTION.PURCHASE_STATUS.TODO) {
                            element.html = `<span class="badge p-2 badge-warning">${element.sub_status.label}</span>`;
                        } else {
                            element.html = `<span class="badge p-2 badge-success">${element.sub_status.label}</span>`;
                        }
                    });

                    switch (this.tableConfig.radioButtonSelectedOption) {
                        case INTERVENTION.PURCHASE_STATUS.PROCESSING:
                            this.items = this.items.filter(element => {
                                return element.status.key === INTERVENTION.PURCHASE_STATUS.PROCESSING;
                            });
                            break;
                        case INTERVENTION.PURCHASE_STATUS.TODO:
                            this.items = this.items.filter(element => {
                                return element.status.key === INTERVENTION.PURCHASE_STATUS.TODO;
                            });
                            break;
                        case INTERVENTION.PURCHASE_STATUS.TO_BE_PAID:
                            this.items = this.items.filter(element => {
                                return element.status.key === INTERVENTION.PURCHASE_STATUS.TO_BE_PAID;
                            });
                            break;
                        default:
                            // set default response by status "PROCESSING"
                            this.items = this.items.filter(element => {
                                return element.status.key === INTERVENTION.PURCHASE_STATUS.PROCESSING;
                            });
                    }
                })
                .catch(error => {
                    this.refreshData = false;
                    console.log(error);
                });
        },
        updateItems(radioButtonSelectedValue, filterValue) {
            // show columns depending on the status
            if (radioButtonSelectedValue !== INTERVENTION.PURCHASE_STATUS.PROCESSING) {
                this.fields = this.allFields.filter(a => a.key !== 'alert_date');
            } else {
                this.fields = this.allFields;
            }

            this.$store.dispatch(`interventionTableState/${TABLE_RADIO_TAB_CHANGE}`, radioButtonSelectedValue);

            this.tableConfig.radioButtonSelectedOption = radioButtonSelectedValue;

            this.tableConfig.filter = filterValue;

            this.items = this.data.filter(element => {
                return element.status.key === radioButtonSelectedValue;
            });
        },
        onTableFiltered(filterValue, filteredItemsLength) {
            this.$store.dispatch(`interventionTableState/${TABLE_FILTER_CHANGE}`, filterValue);
            this.$store.dispatch(`interventionTableState/${TABLE_FILTERED_ITEMS_LENGTH_CHANGE}`, filteredItemsLength);
        },
        saveSortingParams(sortBy, sortDesc) {
            this.$store.dispatch(`interventionTableState/${TABLE_SORTING_CHANGE}`, {
                sortBy,
                sortDesc
            });
        },
        savePage(page) {
            this.$store.dispatch(`interventionTableState/${TABLE_PAGE_CHANGE}`, page);
        },
        saveScrollPosition() {
            this.$store.dispatch(`interventionTableState/${TABLE_SCROLL_POSITION_CHANGE}`, window.scrollY);
        }
    }
};
</script>

<style scoped>
.badge.badge-outline-success {
    border: #28a745 3px solid;
    color: #28a745;
}
</style>
